import React, { Component } from 'react';
import { Container, Menu } from 'semantic-ui-react';

import Stats from './Stats';
import QA from './QA';

class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'Số liệu thống kê'
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(e, { name }) {
    this.setState({ activeTab: name });
  }

  render() {
    const { activeTab } = this.state;
    const {
      totalQuestions,
      correctAnswers,
      timeTakesToComplete,
      questionsAndAnswers,
      retakeQuiz,
      backToHome
    } = this.props;

    return (
      <Container>
        <Menu fluid widths={2}>
           <Menu.Item
            name="Số liệu thống kê"
            active={activeTab === 'Số liệu thống kê'}
            onClick={this.handleTabClick}
          />
          <Menu.Item
            name="Xem đáp án"
            active={activeTab === 'Xem đáp án'}
            onClick={this.handleTabClick}
          />
        </Menu>

        {activeTab === 'Số liệu thống kê' && (
          <Stats
            totalQuestions={totalQuestions}
            correctAnswers={correctAnswers}
            timeTakesToComplete={timeTakesToComplete}
            retakeQuiz={retakeQuiz}
            backToHome={backToHome}
          />
        )}
        {activeTab === 'Xem đáp án' && (
          <QA questionsAndAnswers={questionsAndAnswers} />
        )}
        <br />
      </Container>
    );
  }
}

export default Result;
