
const QUESTIONS_SEG = [];

for (let i = 1; i <= 20; i++) {
  const object = { key: i, text: `${i}`, value: i };
  QUESTIONS_SEG.push(object);
}

export default QUESTIONS_SEG;


