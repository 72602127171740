import React from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import ShareButton from '../ShareButton';

import { calculateGrade } from '../../utils/calculateGrade';
import { timeConverter } from '../../utils/timeConverter';


import AdSense from 'react-adsense';

const Stats = props => {
  const {
    totalQuestions,
    correctAnswers,
    timeTakesToComplete,
    retakeQuiz,
    backToHome
  } = props;

  const score = Number(((correctAnswers * 100) / totalQuestions).toFixed(2));
  const { grade, remarks } = calculateGrade(score);
  const { hours, minutes, seconds } = timeConverter(
    timeTakesToComplete.totalTime - timeTakesToComplete.timerTime
  );

  return (
    <Segment>
      
      <AdSense.Google
        client='ca-pub-6725456370786434'
        slot='8369887648'
        style={{ display: 'block' }}
        responsive='true'
        format='auto'
      />
      <Header as="h1" textAlign="center" block>
        {remarks}
      </Header>
      <Header as="h2" textAlign="center" block>
        Cấp bậc: {grade}
      </Header>
      <Header as="h3" textAlign="center" block>
        Tổng số câu hỏi: {totalQuestions}
      </Header>
      <Header as="h3" textAlign="center" block>
        Câu trả lời đúng: {correctAnswers}
      </Header>
      <Header as="h3" textAlign="center" block>
        Số điểm: {score}%
      </Header>
      <Header as="h3" textAlign="center" block>
        Điểm vượt qua: 60%
      </Header>
      <Header as="h3" textAlign="center" block>
        Thời gian: {`${hours} : ${minutes} : ${seconds}`}
      </Header>
      <div style={{ marginTop: 35 }}>
        <Button
          primary
          content="Làm lại"
          onClick={retakeQuiz}
          size="big"
          icon="redo"
          labelPosition="left"
          style={{ marginRight: 15, marginBottom: 8 }}
        />
        <Button
          color="teal"
          content="Quay về Trang chủ"
          onClick={backToHome}
          size="big"
          icon="home"
          labelPosition="left"
          style={{ marginBottom: 8 }}
        />
        <ShareButton />
      </div>
    </Segment>
  );
};

export default Stats;
