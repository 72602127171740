import React, { Component } from 'react';
import {
  Container,
  Segment,
  Item,
  Dropdown,
  Divider,
  Button
} from 'semantic-ui-react';
import codeImg from '../../assets/images/code.png';
import AdSense from 'react-adsense';
import {
  CATEGORIES,
  NUM_OF_QUESTIONS,
  DIFFICULTY,
  QUESTIONS_SEG,
  COUNTDOWN_TIME
} from '../../constants';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      numOfQ: null,
      difficulty: null,
      seg: null,
      time: null
    };

    this.setValue = this.setValue.bind(this);
  }

  setValue(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { category, numOfQ, difficulty, seg, time } = this.state;

    let allFieldsSelected = false;
    let selectedValues = null;

    //if (category && numOfQ && difficulty && type && time) {
    if (category && numOfQ   && time) {
      allFieldsSelected = true;

      selectedValues = {
        category,
        numOfQ,
        difficulty,
        seg,
        time
      };
    }

    return (
      <Container>
        <Segment>
          <Item.Group divided>
            <Item>
              
              <Item.Content>
                <Item.Header>
                  <h1>Trắc nghiệm Tin học</h1>
                </Item.Header>
                <br />
                <Divider />
                <Item.Meta>
                  <Dropdown
                    fluid
                    selection
                    name="category"
                    placeholder="Chọn Loại câu hỏi"
                    options={CATEGORIES}
                    onChange={(e, { name, value }) =>
                      this.setValue(name, value)
                    }
                  />
                  <br />
                  <Dropdown
                    fluid
                    selection
                    name="numOfQ"
                    placeholder="Chọn Số câu hỏi"
                    options={NUM_OF_QUESTIONS}
                    onChange={(e, { name, value }) =>
                      this.setValue(name, value)
                    }
                  />
                  <br />
                 <Dropdown
                    fluid
                    selection
                    name="seg"
                    placeholder="Chọn thứ tự câu hỏi"
                    options={QUESTIONS_SEG}
                    onChange={(e, { name, value }) =>
                      this.setValue(name, value)
                    }
                  />
                 <br />
                  <Dropdown
                    fluid
                    selection
                    name="time"
                    placeholder="Chọn Thời gian làm bài (phút)"
                    options={COUNTDOWN_TIME}
                    onChange={(e, { name, value }) =>
                      this.setValue(name, value)
                    }
                  />
                </Item.Meta>
                <Divider />
                <Item.Extra>
                  {allFieldsSelected ? (
                    <Button
                      primary
                      content="Làm bài"
                      onClick={() => this.props.startQuiz(selectedValues)}
                      size="big"
                      icon="play"
                      labelPosition="left"
                    />
                  ) : (
                    <Button
                      disabled
                      primary
                      content="Làm bài"
                      size="big"
                      icon="play"
                      labelPosition="left"
                    />
                  )}
                </Item.Extra>

              </Item.Content>
               <AdSense.Google
  client='ca-pub-6725456370786434'
  slot='2139521213'
  style={{ display: 'block' }}
  format='auto'
  responsive='true'
/>
            </Item>
          </Item.Group>
        </Segment>
        <br />



      </Container>
    );
  }
}

export default Main;
