import React from 'react';
import { Table } from 'semantic-ui-react';
import { Label } from 'semantic-ui-react'
const QA = ({ questionsAndAnswers }) => (
  <Table celled striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>STT</Table.HeaderCell>
        <Table.HeaderCell><Label >Câu hỏi</Label></Table.HeaderCell>
        <Table.HeaderCell> <Label color='teal' >Câu trả lời của bạn</Label></Table.HeaderCell>
        <Table.HeaderCell><Label  color='blue' >Câu trả lời Đúng</Label></Table.HeaderCell>
        <Table.HeaderCell>Điểm</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {questionsAndAnswers.map((item, i) => (
        <Table.Row key={i + 1}>
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell> <Label >{item.question}</Label></Table.Cell>
          <Table.Cell>  <Label color='teal' >{item.user_answer}</Label></Table.Cell>
          <Table.Cell > <Label  color='blue' >{item.correct_answer}</Label></Table.Cell>
          <Table.Cell>{item.point}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default QA;
