//const PATH_BASE = 'https://opentdb.com/api.php?';
const PATH_BASE = 'https://quiz.digima.site/itquiz/restapi.php?api=quiz&';


const AMOUNT = 'amount=';
const CATEGORY = 'category=';
const DIFFICULTY = 'difficulty=';
const TYPE = 'type=';
const SEG = 'seg=';

export { PATH_BASE, AMOUNT, CATEGORY, DIFFICULTY, TYPE,SEG};
